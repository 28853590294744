import { useSearchContext } from "@/core/context/SearchContext"
import SearchFiltersItem from "@/search/SearchFiltersItem"
import { SearchTypes } from "@/search/utils/searchUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"

function SearchFilters() {
  const classes = useStyles()
  const { appliedFilters } = useSearchContext()
  const unappliedFilters = SearchTypes.filter((type) => !appliedFilters?.includes(type))
  const theme = useTheme()

  if (!unappliedFilters.length) return null

  return (
    <>
      {theme.palette.type === "light" && (
        <DiscoDivider className={classes.divider} marginTop={0} marginBottom={0} />
      )}
      <div className={classes.container}>
        <DiscoText className={classes.text} variant={"body-md-500-uppercase"}>
          {"I'm looking for"}
        </DiscoText>

        <div className={classes.filters}>
          {unappliedFilters.map((type) => (
            <SearchFiltersItem key={type} type={type} />
          ))}
        </div>
      </div>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  filters: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    marginTop: theme.spacing(1),
    overflowX: "scroll",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
  text: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[300]
        : theme.palette.groovy.neutral[400],
  },
  divider: {
    borderColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[300]
        : theme.palette.groovy.neutral[400],
  },
}))

export default SearchFilters
