import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { usePagination } from "react-instantsearch-hooks-web"

const MAX_SEARCH_PAGES = 10

function SearchPagination() {
  const classes = useStyles()
  const { refine, isFirstPage, isLastPage, currentRefinement, nbPages } = usePagination({
    totalPages: MAX_SEARCH_PAGES,
  })
  const onlyHasOnePageOfResults = nbPages === 1

  return (
    <div className={classes.pagination}>
      {/* Backward Pagination */}
      {!onlyHasOnePageOfResults && (
        <DiscoIconButton
          disabled={isFirstPage}
          onClick={() => handlePaginate("previous")}
          defaultIconStyles={false}
        >
          <DiscoIcon
            icon={"chevron"}
            width={16}
            height={16}
            rotate={"-90"}
            active={!isFirstPage}
          />
          <DiscoText
            color={isFirstPage ? "text.disabled" : undefined}
            variant={"body-sm"}
          >
            {"Prev"}
          </DiscoText>
        </DiscoIconButton>
      )}

      <DiscoText color={"text.secondary"} variant={"body-sm"}>
        {`Page ${currentRefinement + 1} of ${nbPages}`}
      </DiscoText>

      {/* Forward Pagination */}
      {!onlyHasOnePageOfResults && (
        <DiscoIconButton
          disabled={isLastPage}
          onClick={() => handlePaginate("next")}
          defaultIconStyles={false}
        >
          <DiscoText color={isLastPage ? "text.disabled" : undefined} variant={"body-sm"}>
            {"Next"}
          </DiscoText>
          <DiscoIcon
            icon={"chevron"}
            width={16}
            height={16}
            rotate={"90"}
            active={!isLastPage}
          />
        </DiscoIconButton>
      )}
    </div>
  )

  function handlePaginate(direction: "previous" | "next") {
    refine(direction === "previous" ? currentRefinement - 1 : currentRefinement + 1)
  }
}

const useStyles = makeUseStyles((theme) => ({
  pagination: {
    display: "flex",
    gap: theme.spacing(1.5),
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1),
  },
}))

export default SearchPagination
