import { useSearchContext } from "@/core/context/SearchContext"
import SearchHit, { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchPagination from "@/search/search-hits/SearchPagination"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useHits, UseHitsProps } from "react-instantsearch-hooks-web"

function SearchHits(props: UseHitsProps) {
  const { query, closeSearch } = useSearchContext()
  const { hits } = useHits(props)
  const classes = useStyles()

  if (!query) return null
  if (!hits.length)
    return <DiscoEmptyState testid={"SearchHits"} subtitle={"No Results"} />

  return (
    <>
      <div className={classes.container}>
        <div className={classes.hits}>
          {hits.map((hit) => (
            <DiscoContainerButton key={hit.id as string} onClick={closeSearch}>
              <SearchHit key={hit.id as string} hit={hit as SearchHitType} />
            </DiscoContainerButton>
          ))}
        </div>
      </div>

      <SearchPagination />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  },
  hits: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
}))

export default SearchHits
