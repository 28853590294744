import { useSearchContext } from "@/core/context/SearchContext"
import Relay from "@/relay/relayUtils"
import useTrackUserActivity from "@/reporting/useTrackUserActivity"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitCopyButton from "@/search/search-hits/SearchHitCopyButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoSearchText from "@disco-ui/search/DiscoSearchText"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface SearchHitTemplateProps extends TestIDProps {
  hit: SearchHitType
  icon: React.ReactNode
  name?: string | null
  nameAdornment?: React.ReactNode
  details?: string | null | React.ReactNode
  onClick: () => void
  onCopy: (e: React.MouseEvent<HTMLButtonElement>) => void
  classes?: {
    hit?: string
  }
}

function SearchHitTemplate({
  hit,
  details,
  name,
  nameAdornment,
  icon,
  onClick,
  onCopy,
  classes: customClasses,
}: SearchHitTemplateProps) {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()
  const trackSearchHit = useTrackUserActivity()
  const { query } = useSearchContext()

  return (
    <DiscoContainerButton
      tabIndex={0}
      onClick={handleClick}
      className={classNames(
        classes.hit,
        customClasses?.hit,
        showOnHoverClasses.hoverable
      )}
    >
      {/* Icon */}
      <div className={classes.icon}>{icon}</div>

      <div className={classes.text}>
        {/* Name */}
        {name && (
          <div className={classes.name}>
            <DiscoSearchText variant={"body-sm-500"} text={name} truncateText={1} />
            {nameAdornment}
          </div>
        )}

        {/* Details */}
        {details && (
          <div className={classes.details}>
            {typeof details === "string" ? (
              <DiscoText
                variant={"body-sm"}
                color={"groovy.neutral.400"}
                truncateText={1}
              >
                {details}
              </DiscoText>
            ) : (
              details
            )}
          </div>
        )}
      </div>

      {/* CTAs */}
      <SearchHitCopyButton onClick={onCopy} className={showOnHoverClasses.showable} />
    </DiscoContainerButton>
  )

  function handleClick() {
    onClick()

    // Track the search hit click
    trackSearchHit({ kind: "search_hit", entityId: hit.id, metadata: { search: query } })
  }
}

const useStyles = makeUseStyles((theme) => ({
  hit: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "space-between",
    padding: theme.spacing(1.25, 2),
    borderRadius: theme.measure.borderRadius.medium,
    minHeight: "48px",
    maxHeight: "66px",

    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1),
  },
  text: {
    width: "100%",
    minWidth: 0,
  },
  name: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    flex: "1 1 auto",
    width: "100%",
    minWidth: 0,
  },
  details: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    flex: "1 1 auto",
    width: "100%",
    minWidth: 0,
    ...styleIf(theme.palette.type === "dark", {
      "& p": {
        color: theme.palette.groovy.onDark[200],
      },
    }),
  },
}))

export const SearchHitTemplateSkeleton: React.FC<
  Pick<SearchHitTemplateProps, "classes">
> = ({ classes: customClasses }) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.hit, customClasses?.hit)}>
      <div className={classes.icon}>
        {<Skeleton variant={"circle"} height={40} width={40} />}
      </div>
      <div className={classes.text}>
        <div className={classes.name}>
          <DiscoTextSkeleton width={"60%"} />
        </div>
        <div className={classes.details}>
          <DiscoTextSkeleton width={"80%"} />
        </div>
      </div>
    </div>
  )
}

export default Relay.withSkeleton<SearchHitTemplateProps>({
  component: SearchHitTemplate,
  skeleton: SearchHitTemplateSkeleton,
})
