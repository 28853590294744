import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import MemberProfileProvider from "@/user/settings/context/MemberProfileContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const ProfileSettingsDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "profile-settings-drawer-content" */ "@/user/settings/ProfileSettingsDrawerContent"
    )
)

function ProfileSettingsDrawer() {
  const globalDrawer = useGlobalDrawer("profileSettings")
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const unsavedChangesModal = useInitUnsavedChangesModalContext()

  return (
    <DiscoDrawer
      open={globalDrawer.isOpen && Boolean(activeOrganization.viewerMembership)}
      onClose={handleCloseDrawer}
      size={"large"}
      containerClasses={{
        drawerContainer: classes.drawer,
      }}
    >
      <UnsavedChangesModalProvider {...unsavedChangesModal}>
        {/* Content */}
        {globalDrawer.params.drawerProfileId && (
          <MemberProfileProvider profileId={globalDrawer.params.drawerProfileId}>
            <ProfileSettingsDrawerContent closeDrawer={handleCloseDrawer} />
          </MemberProfileProvider>
        )}
      </UnsavedChangesModalProvider>
    </DiscoDrawer>
  )

  function handleCloseDrawer() {
    unsavedChangesModal.handleLeave({
      onLeave: globalDrawer.close,
    })
  }
}

const useStyles = makeUseStyles({
  drawer: {
    padding: 0,
  },
})

export default ProfileSettingsDrawer
